// Constants.js

const prod = {
	url: {
		API_URL: "http://localhost:1337/",
	},
	analyticsTrackingID: 'UA-156419757-1'
};

const dev = {
	url: {
		API_URL: "http://localhost:1337/"
	},
	analyticsTrackingID: null
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
