import React from "react";
import styled from "styled-components";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import { createBrowserHistory } from "history";
import { config } from './constants/constants.js'



const trackingId = config.analyticsTrackingID;

console.log(window.location.pathname + window.location.search)

ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);




const Wrapper = styled.div`
  height: 100%;
  width: 100%;

  margin: 0;
  // overflow-y: auto;

  @media screen and (max-width: 640px) {
  }
  @media screen and (min-width: 641px) {
  }
  @media screen and (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    flex-direction: row;
    height: 100vh;
    overflow-y: hidden;
  }
  @media screen and (min-width: 1280px) {
  }
`;

const ColumnWrapper = styled.div`
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  @media screen and (max-width: 640px) {
    height: 70px;
  }
  @media screen and (min-width: 641px) {
    height: 125px;
  }
  @media screen and (min-width: 1024px) {
    width: calc(50vh);
    min-width: 320px;
    min-height: 568px;
    height: 100%;
  }
  @media screen and (min-width: 1280px) {
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 640px) {
  }

  @media screen and (min-width: 641px) {
  }

  @media screen and (min-width: 1024px) {
    flex-direction: column;
  }

  @media screen and (min-width: 1280px) {
  }
`;

const LogoWrapper = styled.div`
  background-color: #ffb000;
  width: 50%;
  height: 100%;
  @media screen and (max-width: 640px) {
  }
  @media screen and (min-width: 641px) {
  }
  @media screen and (min-width: 1024px) {
    width: 100%;
    height: 50%;
  }
  @media screen and (min-width: 1280px) {
  }
`;

const Logo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BodyWrapper = styled.div`
  width: calc(100% - 100px);
  
  min-width: 50vw;
  
  padding: 50px;
  
  @media screen and (max-width: 640px) {
  }
  @media screen and (min-width: 641px) {
  }
  @media screen and (min-width: 1024px) {
    width: calc(100% - 50vh - 100px);
    overflow-y: auto;
  }
  @media screen and (min-width: 1280px) {
  }
`;

const Body = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 36px;
  font-weight: 300;
  color: #313131;
  text-align: center;
  margin-bottom: 90px;
  @media screen and (max-width: 640px) {
  }
  @media screen and (min-width: 641px) {
  }
  @media screen and (min-width: 1024px) {
    // margin-bottom: 100px;
    margin-bottom: 110px;
  }
  @media screen and (min-width: 1280px) {
    // margin-bottom: 110px;
  }
  width: 100%;
`;

const ArticleWrapper = styled.div`
  margin-bottom: 70px;
  &:last-child {
    margin-bottom: 0;
  }

  // max-width: 620px;
  max-width: 750px;

  @media screen and (max-width: 640px) {
  }
  @media screen and (min-width: 641px) {
  }
  @media screen and (min-width: 1024px) {
    margin-bottom: 110px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media screen and (min-width: 1280px) {
  }
`;

const Article = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 640px) {
  }
  @media screen and (min-width: 641px) {
  }
  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
  @media screen and (min-width: 1280px) {
  }
`;

const VisualWrap = styled.div`
  width: 230px;
  flex-shrink: 0;
  margin-bottom: 20px;
  @media screen and (max-width: 640px) {
  }
  @media screen and (min-width: 641px) {
  }
  @media screen and (min-width: 1024px) {
    margin-bottom: 0;
  }
  @media screen and (min-width: 1280px) {
  }
`;

const Visual = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DescriptionWrapper = styled.div`
  @media screen and (max-width: 640px) {
  }
  @media screen and (min-width: 641px) {
  }
  @media screen and (min-width: 1024px) {
    margin-left: 30px;
  }
  @media screen and (min-width: 1280px) {
  }
`;

const Description = styled.div`
  width: 100%;
`;

const Feature = styled.div`
  font-size: 25px;
  font-weight: 400;
  color: #474747;
  text-align: center;
  @media screen and (max-width: 640px) {
  }
  @media screen and (min-width: 641px) {
  }
  @media screen and (min-width: 1024px) {
    text-align: left;
  }
  @media screen and (min-width: 1280px) {
  }
`;
const Details = styled.div`
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #7b7b7b;
  line-height: 30px;
  text-align: center;
  @media screen and (max-width: 640px) {
  }

  @media screen and (min-width: 641px) {
  }

  @media screen and (min-width: 1024px) {
    text-align: left;
  }

  @media screen and (min-width: 1280px) {
  }
`;

const LogoIcon = styled.img`
  @media screen and (max-width: 640px) {
    height: 60%;
  }

  @media screen and (min-width: 641px) {
    height: 60%;
  }

  @media screen and (min-width: 1024px) {
    width: 50%;
  }

  @media screen and (min-width: 1280px) {
  }
`;

const ContactWrapper = styled.a`
  background-color: #202020;
  width: 50%;
  height: 100%;
  text-decoration: none;

  &:hover {
    background-color: #292929;
  }

  @media screen and (max-width: 640px) {
  }

  @media screen and (min-width: 641px) {
  }

  @media screen and (min-width: 1024px) {
    width: 100%;
    height: 50%;
  }

  @media screen and (min-width: 1280px) {
  }
`;

const Contact = styled.div`
 
  width: 100%
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  @media screen and (max-width: 640px) {
    
  }

  @media screen and (min-width: 641px) {
               
  }

  @media screen and (min-width: 1024px) {

  }

  @media screen and (min-width: 1280px) {
               
  }
`;
const CallToAction = styled.div`
  ont-size: 16px;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  @media screen and (max-width: 640px) {
    display: none;
  }

  @media screen and (min-width: 641px) {
    font-size: 27px;
  }

  @media screen and (min-width: 1024px) {
  }

  @media screen and (min-width: 1280px) {
  }
`;
const Address = styled.div`
  font-size: 16px;
  font-weight: 300;
  color: #ffffff;
  text-align: center;

  @media screen and (max-width: 640px) {
    font-weight: 400;
  }
  @media screen and (min-width: 641px) {
    font-size: 27px;
  }
  @media screen and (min-width: 1024px) {
  }
  @media screen and (min-width: 1280px) {
  }
`;

const SmartBoxImage = styled.img`
  width: 210px;
`;

const PiggyBankImage = styled.img`
  width: 230px;
`;

const KeysImage = styled.img`
  width: 150px;
`;

function App() {
  return (
    <Wrapper>
      <ColumnWrapper>
        <Column>
          <LogoWrapper>
            <Logo>
              <LogoIcon src={require("./assets/logo/panko-logo.svg")} />
            </Logo>
          </LogoWrapper>
          <ContactWrapper href="mailto:hello@panko.ro">
            <Contact>
              <CallToAction>Let's talk</CallToAction>
              <Address>hello@panko.ro</Address>
            </Contact>
          </ContactWrapper>
        </Column>
      </ColumnWrapper>
      <BodyWrapper>
        <Body>
          <Title>Enterprise solution for food ordering</Title>
          <ArticleWrapper>
            <Article>
              <VisualWrap>
                <Visual>
                  <SmartBoxImage
                    src={require("./assets/images/smart-box.png")}
                    alt="smart delivery box"
                  />
                </Visual>
              </VisualWrap>
              <DescriptionWrapper>
                <Description>
                  <Feature>Smart Delivery Box</Feature>
                  <Details>
                    We know how busy you are. Our custom made, smart delivery
                    box receives and stores the food safely if your employees
                    are in a meeting when the courier arrives.
                  </Details>
                </Description>
              </DescriptionWrapper>
            </Article>
          </ArticleWrapper>
          <ArticleWrapper>
            <Article>
              <VisualWrap>
                <Visual>
                  <PiggyBankImage
                    src={require("./assets/images/piggy-bank.png")}
                    alt="smart delivery box"
                  />
                </Visual>
              </VisualWrap>
              <DescriptionWrapper>
                <Description>
                  <Feature>Multiple Payment Options</Feature>
                  <Details>
                    We give your employees the possibility of paying from a
                    personal virtual balance, which they can charge from a meal
                    or credit card. We also support orders charged directly to
                    the company.
                  </Details>
                </Description>
              </DescriptionWrapper>
            </Article>
          </ArticleWrapper>
          <ArticleWrapper>
            <Article>
              <VisualWrap>
                <Visual>
                  <KeysImage
                    src={require("./assets/images/keys.png")}
                    alt="smart delivery box"
                  />
                </Visual>
              </VisualWrap>
              <DescriptionWrapper>
                <Description>
                  <Feature>Tailored Experience</Feature>
                  <Details>
                    Your tenant, your rules. Every company gets to choose its
                    own restaurants, delivery hours, payment options, and other
                    preferences.
                  </Details>
                </Description>
              </DescriptionWrapper>
            </Article>
          </ArticleWrapper>
        </Body>
      </BodyWrapper>
    </Wrapper>
  );
}

export default App;